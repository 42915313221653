@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/objects/links/links';
@import '../../styles/tools/mixins';

.banner {
  display: flex;
  justify-items: center;
  height: 96px;
  padding: 20px;
  max-height: 96px;

  @include media-breakpoint-up('sm') {
    height: 58px;
    max-height: 58px;
  }

  * {
    padding: 0;
    margin: 0;
  }

  &.info {
    background-color: $alert-info-background;
    border-bottom: 4px solid $colour-informative-blue;

    .icon {
      color: $colour-informative-blue;
    }
  }

  &.warning {
    background-color: $alert-warning-background;
    border-bottom: 4px solid $colour-warning;

    .icon {
      color: $colour-warning;
    }
  }

  &.danger {
    background-color: $alert-error-background;
    border-bottom: 4px solid $colour-error-red;

    .icon {
      color: $colour-error-red;
    }
  }

  .icon {
    font-size: 18px;
    margin-right: 12px;
    margin-top: -5px;
    @include media-breakpoint-up('sm') {
      margin-top: 0;
    }
  }

  .content {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      text-decoration: underline;
    }

    @include media-breakpoint-up('sm') {
      align-items: center;
    }
  }

  .html {
    * {
      font-size: 16px;
    }
  }

  .closeBtn {
    align-self: flex-start;
    background: transparent;
    border: 0;
    cursor: pointer;
    margin-top: -5px;

    @include media-breakpoint-up('sm') {
      align-self: center;
      margin-top: 0;
  }
  }
}
